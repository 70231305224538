export const navLinks = [
  {
    title: 'Pricing',
    href: '/pricing',
  },
  {
    title: 'Demo',
    href: 'https://app.logspot.io/demo',
    external: true,
  },
  // {
  //   title: 'Blog',
  //   href: '/blog',
  // },
  {
    title: 'Docs',
    href: '/docs',
  },
];
